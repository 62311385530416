import React from 'react';
import "./src/scss/global.scss";

window.process = {
  env: {
    NODE_ENV: "production",
  },
};
export const onRouteUpdate = loc => {

}