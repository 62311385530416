// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bisolnatur-kullanicilari-anlatiyor-tsx": () => import("./../../../src/pages/bisolnatur-kullanicilari-anlatiyor.tsx" /* webpackChunkName: "component---src-pages-bisolnatur-kullanicilari-anlatiyor-tsx" */),
  "component---src-pages-bize-ulasin-tsx": () => import("./../../../src/pages/bize-ulasin.tsx" /* webpackChunkName: "component---src-pages-bize-ulasin-tsx" */),
  "component---src-pages-cerez-politikasi-tsx": () => import("./../../../src/pages/cerez-politikasi.tsx" /* webpackChunkName: "component---src-pages-cerez-politikasi-tsx" */),
  "component---src-pages-gizlilik-politikasi-ve-kvkk-aydinlatma-metni-tsx": () => import("./../../../src/pages/gizlilik-politikasi-ve-kvkk-aydinlatma-metni.tsx" /* webpackChunkName: "component---src-pages-gizlilik-politikasi-ve-kvkk-aydinlatma-metni-tsx" */),
  "component---src-pages-hakkimizda-tsx": () => import("./../../../src/pages/hakkimizda.tsx" /* webpackChunkName: "component---src-pages-hakkimizda-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mesaj-gonderildi-tsx": () => import("./../../../src/pages/mesaj-gonderildi.tsx" /* webpackChunkName: "component---src-pages-mesaj-gonderildi-tsx" */),
  "component---src-pages-nereden-satin-alabilirim-tsx": () => import("./../../../src/pages/nereden-satin-alabilirim.tsx" /* webpackChunkName: "component---src-pages-nereden-satin-alabilirim-tsx" */),
  "component---src-pages-oksuruk-alerjik-oksuruk-nedir-ne-iyi-gelir-index-tsx": () => import("./../../../src/pages/oksuruk/alerjik-oksuruk-nedir-ne-iyi-gelir/index.tsx" /* webpackChunkName: "component---src-pages-oksuruk-alerjik-oksuruk-nedir-ne-iyi-gelir-index-tsx" */),
  "component---src-pages-oksuruk-balgam-nasil-atilir-index-tsx": () => import("./../../../src/pages/oksuruk/balgam-nasil-atilir/index.tsx" /* webpackChunkName: "component---src-pages-oksuruk-balgam-nasil-atilir-index-tsx" */),
  "component---src-pages-oksuruk-balgam-nedir-neden-olur-index-tsx": () => import("./../../../src/pages/oksuruk/balgam-nedir-neden-olur/index.tsx" /* webpackChunkName: "component---src-pages-oksuruk-balgam-nedir-neden-olur-index-tsx" */),
  "component---src-pages-oksuruk-balgamli-oksuruk-index-tsx": () => import("./../../../src/pages/oksuruk/balgamli-oksuruk/index.tsx" /* webpackChunkName: "component---src-pages-oksuruk-balgamli-oksuruk-index-tsx" */),
  "component---src-pages-oksuruk-bogaz-agrisi-nedenleri-nelerdir-index-tsx": () => import("./../../../src/pages/oksuruk/bogaz-agrisi-nedenleri-nelerdir/index.tsx" /* webpackChunkName: "component---src-pages-oksuruk-bogaz-agrisi-nedenleri-nelerdir-index-tsx" */),
  "component---src-pages-oksuruk-bogaz-agrisi-nedir-nasil-gecer-index-tsx": () => import("./../../../src/pages/oksuruk/bogaz-agrisi-nedir-nasil-gecer/index.tsx" /* webpackChunkName: "component---src-pages-oksuruk-bogaz-agrisi-nedir-nasil-gecer-index-tsx" */),
  "component---src-pages-oksuruk-bogaz-tahrisi-belirtileri-nelerdir-nasil-gecer-index-tsx": () => import("./../../../src/pages/oksuruk/bogaz-tahrisi-belirtileri-nelerdir-nasil-gecer/index.tsx" /* webpackChunkName: "component---src-pages-oksuruk-bogaz-tahrisi-belirtileri-nelerdir-nasil-gecer-index-tsx" */),
  "component---src-pages-oksuruk-cocuklarda-bogaz-agrisi-nasil-gecer-index-tsx": () => import("./../../../src/pages/oksuruk/cocuklarda-bogaz-agrisi-nasil-gecer/index.tsx" /* webpackChunkName: "component---src-pages-oksuruk-cocuklarda-bogaz-agrisi-nasil-gecer-index-tsx" */),
  "component---src-pages-oksuruk-cocuklarda-oksuruge-ne-iyi-gelir-index-tsx": () => import("./../../../src/pages/oksuruk/cocuklarda-oksuruge-ne-iyi-gelir/index.tsx" /* webpackChunkName: "component---src-pages-oksuruk-cocuklarda-oksuruge-ne-iyi-gelir-index-tsx" */),
  "component---src-pages-oksuruk-hamilelikte-oksuruk-nasil-gecer-index-tsx": () => import("./../../../src/pages/oksuruk/hamilelikte-oksuruk-nasil-gecer/index.tsx" /* webpackChunkName: "component---src-pages-oksuruk-hamilelikte-oksuruk-nasil-gecer-index-tsx" */),
  "component---src-pages-oksuruk-index-tsx": () => import("./../../../src/pages/oksuruk/index.tsx" /* webpackChunkName: "component---src-pages-oksuruk-index-tsx" */),
  "component---src-pages-oksuruk-kronik-oksuruk-nedir-nasil-gecer-index-tsx": () => import("./../../../src/pages/oksuruk/kronik-oksuruk-nedir-nasil-gecer/index.tsx" /* webpackChunkName: "component---src-pages-oksuruk-kronik-oksuruk-nedir-nasil-gecer-index-tsx" */),
  "component---src-pages-oksuruk-kuru-oksuruk-nasil-gecer-index-tsx": () => import("./../../../src/pages/oksuruk/kuru-oksuruk-nasil-gecer/index.tsx" /* webpackChunkName: "component---src-pages-oksuruk-kuru-oksuruk-nasil-gecer-index-tsx" */),
  "component---src-pages-oksuruk-kuru-oksuruk-nedir-index-tsx": () => import("./../../../src/pages/oksuruk/kuru-oksuruk-nedir/index.tsx" /* webpackChunkName: "component---src-pages-oksuruk-kuru-oksuruk-nedir-index-tsx" */),
  "component---src-pages-oksuruk-oksuruge-ne-iyi-gelir-nasil-gecer-index-tsx": () => import("./../../../src/pages/oksuruk/oksuruge-ne-iyi-gelir-nasil-gecer/index.tsx" /* webpackChunkName: "component---src-pages-oksuruk-oksuruge-ne-iyi-gelir-nasil-gecer-index-tsx" */),
  "component---src-pages-oksuruk-oksuruk-nedir-index-tsx": () => import("./../../../src/pages/oksuruk/oksuruk-nedir/index.tsx" /* webpackChunkName: "component---src-pages-oksuruk-oksuruk-nedir-index-tsx" */),
  "component---src-pages-oksuruk-ses-kisikligi-nedenleri-nelerdir-nasil-gecer-index-tsx": () => import("./../../../src/pages/oksuruk/ses-kisikligi-nedenleri-nelerdir-nasil-gecer/index.tsx" /* webpackChunkName: "component---src-pages-oksuruk-ses-kisikligi-nedenleri-nelerdir-nasil-gecer-index-tsx" */),
  "component---src-pages-oksuruk-soguk-alginligi-nedir-belirtileri-nelerdir-index-tsx": () => import("./../../../src/pages/oksuruk/soguk-alginligi-nedir-belirtileri-nelerdir/index.tsx" /* webpackChunkName: "component---src-pages-oksuruk-soguk-alginligi-nedir-belirtileri-nelerdir-index-tsx" */),
  "component---src-pages-urunler-index-tsx": () => import("./../../../src/pages/urunler/index.tsx" /* webpackChunkName: "component---src-pages-urunler-index-tsx" */),
  "component---src-pages-urunler-products-json-slug-fiyat-index-tsx": () => import("./../../../src/pages/urunler/{productsJson.slug}/fiyat/index.tsx" /* webpackChunkName: "component---src-pages-urunler-products-json-slug-fiyat-index-tsx" */),
  "component---src-pages-urunler-products-json-slug-merak-edilenler-index-tsx": () => import("./../../../src/pages/urunler/{productsJson.slug}/merak-edilenler/index.tsx" /* webpackChunkName: "component---src-pages-urunler-products-json-slug-merak-edilenler-index-tsx" */),
  "component---src-pages-urunler-products-json-slug-tsx": () => import("./../../../src/pages/urunler/{productsJson.slug}.tsx" /* webpackChunkName: "component---src-pages-urunler-products-json-slug-tsx" */),
  "component---src-pages-yasal-uyari-ve-kullanim-kosullari-tsx": () => import("./../../../src/pages/yasal-uyari-ve-kullanim-kosullari.tsx" /* webpackChunkName: "component---src-pages-yasal-uyari-ve-kullanim-kosullari-tsx" */)
}

